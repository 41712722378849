import React from "react";

const Talk1 = () => {
    return <path id="talk1" fill="white" stroke="black" strokeWidth="10"
        d="M 432.00,277.00
           C 432.00,277.00 358.00,234.00 358.00,234.00
             348.11,234.02 331.70,238.65 321.00,240.58
             321.00,240.58 270.00,246.09 270.00,246.09
             270.00,246.09 260.00,247.00 260.00,247.00
             217.42,247.49 179.52,244.75 138.00,234.37
             99.85,224.83 59.03,208.34 31.00,180.00
             7.81,156.54 -1.99,125.71 13.37,95.00
             18.05,85.64 24.65,77.38 32.00,70.00
             43.27,58.70 55.12,50.60 69.00,42.86
             95.95,27.83 127.84,18.23 158.00,12.20
             223.23,-0.84 305.58,1.06 369.00,22.00
             400.57,32.42 432.17,46.09 456.00,70.00
             471.74,85.79 482.25,104.23 482.00,127.00
             481.69,154.24 462.65,177.58 442.00,193.35
             435.20,198.54 426.50,203.95 419.00,208.14
             416.09,209.76 408.60,212.91 407.02,215.39
             405.34,218.03 432.00,273.26 432.00,277.00 Z" />
}

export default Talk1;

