import React from "react";

const Talk2 = () => {
    return <path id="talk2" fill="white" stroke="black" strokeWidth="10"
        d="M 68.00,277.00
           C 68.00,277.00 142.00,234.00 142.00,234.00
             151.89,234.02 168.30,238.65 179.00,240.58
             179.00,240.58 230.00,246.09 230.00,246.09
             230.00,246.09 240.00,247.00 240.00,247.00
             282.58,247.49 320.48,244.75 362.00,234.37
             400.15,224.83 440.97,208.34 469.00,180.00
             492.19,156.54 501.99,125.71 486.63,95.00
             481.95,85.64 475.35,77.38 468.00,70.00
             456.73,58.70 444.88,50.60 431.00,42.86
             404.05,27.83 372.16,18.23 342.00,12.20
             276.77,-0.84 194.42,1.06 131.00,22.00
             99.43,32.42 67.83,46.09 44.00,70.00
             28.26,85.79 17.75,104.23 18.00,127.00
             18.31,154.24 37.35,177.58 58.00,193.35
             64.80,198.54 73.50,203.95 81.00,208.14
             83.91,209.76 91.40,212.91 92.98,215.39
             94.66,218.03 68.00,273.26 68.00,277.00 Z" />
}

export default Talk2;