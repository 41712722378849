import React from 'react';

const Umbrella = (props) => {
    return (
        <g id={props.id} className={props.className} transform={props.transform}>
                <path className="logo_handle" fill="none" stroke={props.handle? props.handle: "brown"} strokeWidth="10" d="M 250.00,195.00
                    C 250.00,195.00 250.00,347.00 250.00,347.00" strokeLinecap="rounded" strokeLinejoin="round"/>
                <path className="logo_back" fill={props.color.back} d="M 1.00,249.00
                    C 1.00,221.71 5.68,195.86 14.33,170.00
                        21.73,147.90 33.46,124.85 47.15,106.00
                        62.99,84.20 81.05,65.19 103.00,49.43
                        162.10,7.00 241.28,-8.78 312.00,8.89
                        329.26,13.21 343.97,18.84 360.00,26.48
                        423.15,56.60 473.92,114.95 491.63,183.00
                        495.97,199.69 497.07,206.01 499.14,223.00
                        499.98,228.36 500.94,239.10 499.14,244.00
                        496.10,237.44 495.62,235.16 491.08,229.00
                        462.12,189.74 399.57,185.03 361.00,212.21
                        353.82,217.27 344.10,227.24 339.90,235.00
                        337.43,239.57 336.86,244.79 334.00,249.00
                        326.90,236.48 326.80,230.48 314.91,219.29
                        283.04,189.27 227.76,187.00 193.00,213.50
                        185.45,219.26 178.95,225.62 174.31,234.00
                        174.31,234.00 167.00,250.00 167.00,250.00
                        158.31,233.48 158.59,228.64 143.00,215.30
                        112.87,189.51 63.42,188.91 31.00,210.75
                        23.09,216.07 14.34,224.80 9.45,233.00
                        9.45,233.00 1.00,249.00 1.00,249.00 Z
                    M 237.00,499.00
                    C 237.00,499.00 265.00,500.00 265.00,500.00
                        265.00,500.00 237.00,499.00 237.00,499.00 Z" />
                <path className="logo_J" fill="none" stroke={props.bottom? props.bottom : "black"} strokeWidth={props.strokeWidth} d="M 250.00,311.00
                    C 250.00,311.00 250.00,424.00 250.00,424.00
                        250.00,500.00 167.00,500.00 167.00,424.00" />
                <path className="logo_front" fill={props.color.front} d="M 166.00,250.00
                    C 162.24,234.53 171.09,202.74 175.58,187.00
                        188.31,142.33 202.75,98.14 220.00,55.00
                        220.00,55.00 239.26,12.00 239.26,12.00
                        241.33,7.99 245.62,-0.07 251.00,0.58
                        255.88,1.16 260.66,9.08 262.99,13.00
                        269.64,24.23 276.75,41.60 281.42,54.00
                        298.26,98.64 314.87,150.62 325.88,197.00
                        325.88,197.00 333.88,233.00 333.88,233.00
                        334.66,238.46 336.11,244.69 333.88,250.00
                        332.35,230.36 317.70,215.63 301.00,206.78
                        270.89,190.83 226.45,190.93 197.00,208.45
                        186.94,214.44 176.67,223.40 171.37,234.00
                        171.37,234.00 166.00,250.00 166.00,250.00 Z" />
                </g>
    );
};

export default Umbrella;